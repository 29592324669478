/* Fonts */

$project-font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$project-font-size-body: 16px;
$project-font-size-stack: 1rem;
$project-font-size-xxxl: 1rem * 2.75;
$project-font-size-xxl: 1rem * 2.5;
$project-font-size-xl: 1rem * 2;
$project-font-size-lg: 1rem * 1.5;
$project-font-size-md: 1rem * 1.25;
$project-font-size-sm: 1rem * 0.75;
$project-font-size-xs: 1rem * 0.625;

$project-font-line-height-stack: 1.5;
$project-font-line-height-sm: 1.3;
$project-font-line-height-xs: 1.2;

/* Colors */

$color-primary: #033e7e;
$color-secondary: #d11341;
$color-warning: #bd3547;
$color-white: #ffffff;
$color-black: #000000;
$color-gray: #c8c8c8;
$color-gray-light: #f0f0f0;
$color-loader-background: rgba(0, 0, 0, 0.5);
$color-background: #f7f7f7;

$box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.26);
$box-shadow-group-card: 0 12px 24px -4px rgba(0, 0, 0, 0.75);

$linear-gradient-group-card: linear-gradient(
  0deg,
  rgba(209, 19, 65, 1) 0%,
  rgba(209, 19, 65, 1) 20%,
  rgba(3, 62, 126, 1) 100%
);

/* Radius */

$project-radius-round: 50%;
$project-radius-pill: 100px;
$project-radius: 4px;
$project-radius-sm: 2px;
$project-radius-lg: 0.5rem;
$project-radius-xl: 0.75rem;

/* Spacings */

$project-gutter: 1rem;
$project-gutter-xs: $project-gutter * 0.25;
$project-gutter-sm: $project-gutter * 0.5;
$project-gutter-075: $project-gutter * 0.75;
$project-gutter-xl: $project-gutter * 1.5;
$project-gutter-2g: $project-gutter * 2;
$project-gutter-3l: $project-gutter * 3;
$project-gutter-4xl: $project-gutter * 4;

/* Sizes */

$project-size-main-container: 84rem; // 1344px
$project-size-input-height: 3.375rem; // 54px
$project-size-button-height: 3.75rem; // 60px
$project-size-avatar: 4.0625rem; // 65px
$project-size-avatar-sm: 3.5rem; // 56px

$project-size-icon-sm: 1rem;
$project-size-icon: 1.5rem;
$project-size-icon-md: 1.75rem;
$project-size-icon-lg: 2rem;
$project-size-icon-xl: 2.75rem;

$project-size-header: 6rem;

/* Screens */

$project-screen-full-hd: 120rem; // 1920px
$project-screen-laptop: 80rem; // 1280px
$project-screen-laptop-sm: 64rem; // 1024px
$project-screen-tablet-landscape: 61.93rem; // 991px
$project-screen-tablet-portrait: 48rem; // 768px
$project-screen-mobile-landscape: 46.25rem; // 740px
$project-screen-mobile-portrait-lg: 30rem; // 480px
$project-screen-mobile-portrait: 23.4375rem; // 375px
$project-screen-mobile-sm-portrait: 20rem; // 320px

/* Z indexes */

$project-z-index-header: 1;

/* Linear gradients */

$side-bar-active-link: linear-gradient(
  270.79deg,
  rgba(255, 242, 0, 0) 0%,
  rgba(209, 19, 65, 0.2) 100%
);
