@import '../../styles/abstract/variables';

.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $color-loader-background;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 2000;
}
