@import '../../styles/abstract/variables';

.container {
  position: fixed;
  width: 100%;
  background: $color-primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 40px;
  z-index: 2;
}

.logo {
  border: none;
  background: transparent;

  h1 {
    font-family: 'Bebas Neue', sans-serif;
    color: $color-white;
    font-size: 24px;
    letter-spacing: 7.2px;
  }
}
